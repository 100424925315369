.p-inputtext {
  width: 100%;
  border-color: $lightest-gray;
  border-radius: 0;
  font-size: 10px;

  &::placeholder {
    opacity: 1;
  }
}

app-error-message,
.custom-error-message {
  .text-danger {
    font-size: .625rem;
    font-weight: 400;
  }
}

.p-input-icon-left>em:first-of-type {
  left: 0.5rem;
  color: #6c757d;
}

.p-input-icon-left>em,
.p-input-icon-right>em {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}

.p-inputtext {
  padding: 0.41rem 0.5625rem;
}

.p-inputtext:enabled:hover,
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border-color: $secondary;
}

.p-inputtext:enabled:focus,
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  box-shadow: none;
  border-color: $secondary;
}

.search-global {
  min-width: 600px;

  .p-inputgroup-addon {
    padding: 0 .625rem 0 0;
    border-color: $lightest-gray;
    background-color: $grid;
    min-width: 1px;

    &:last-child {
      border-right: 1px solid $lightest-gray;
    }

    .p-dropdown {
      width: auto;
      background-color: transparent;
      border: none;

      .p-dropdown-label {
        color: $dark-gray;
      }
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
      background: $primary;
      color: $white;
    }
  }

  .p-inputgroup-addon:first-child,
  .p-inputgroup button:first-child,
  .p-inputgroup input:first-child,
  .p-inputgroup>.p-inputwrapper:first-child>.p-component,
  .p-inputgroup>.p-inputwrapper:first-child>.p-component>.p-inputtext {
    border-radius: 20px;
    border-radius: 20px;
    width: auto;
  }

  .p-inputgroup-addon:last-child,
  .p-inputgroup button:last-child,
  .p-inputgroup input:last-child,
  .p-inputgroup>.p-inputwrapper:last-child>.p-component,
  .p-inputgroup>.p-inputwrapper:last-child>.p-component>.p-inputtext {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .p-dropdown .p-dropdown-trigger {
    width: auto;
  }

  .material-symbols-outlined {
    top: 5px;
    right: 10px;
    z-index: 1;
  }
}

p-inputnumber,
.p-inputnumber {
  display: block !important;
}

.p-datepicker {
  table {
    font-size: 12px;
    margin: 0.5rem 0;

    td>span {
      width: 1rem;
      height: 1rem;
    }
  }

  .p-datepicker-header {

    .p-datepicker-next,
    .p-datepicker-prev {
      width: 1rem;
      height: 1rem;
    }

    .p-datepicker-title {

      .p-datepicker-year,
      .p-datepicker-month {
        padding: 0;
      }
    }
  }

  .p-link {
    font-size: 12px;
  }
}

.p-calendar {
  width: 100%;
}

.v-tabs-document {
  .p-fileupload {
    &.custom-document {
      .p-button.p-fileupload-choose {
        left: 312px;
      }
    }

    &.quickview-document {
      .p-button.p-fileupload-choose {
        left: 220px;
      }
    }
  }
}

.p-fileupload {
  &.custom-document {
    position: relative;
    border: 1px dashed $light-blue;
    background-color: #F0F9FF;
    border-radius: 3px;

    &.custom-document-carrier-customer-h-tabs {
      .p-button.p-fileupload-choose {
        left: 410px;
      }
    }

    &.custom-document-report {
      .p-button.p-fileupload-choose {
        left: 525px;
      }
    }

    .p-fileupload-buttonbar {
      padding: 0;
      border: 0;
    }

    p-button {
      position: absolute;
      top: 58px;
      right: -173px;
      font-size: 14px;
      background: transparent;
      border: none;
      color: #3EA7AB;
      z-index: 1;

      .p-button {
        width: 159px;
        margin-right: 2px;
      }
    }

    .p-button.p-fileupload-choose {
      position: absolute;
      bottom: 14px;
      left: 320px;
      font-size: 14px;
      background: transparent;
      border: none;
      color: $secondary;
      z-index: 1;

      &.p-focus {
        box-shadow: none;
      }

      .p-icon-wrapper {
        visibility: hidden;
      }
    }

    .p-fileupload-buttonbar,
    .p-fileupload-content {
      background-color: transparent;
      font-size: 14px;
      border: none;
    }

    .p-fileupload-content {
      padding: 19px 19px;

      .p-message-icon {
        line-height: 0;
      }

      .p-progressbar {
        display: none;
      }
    }

    .p-fileupload-row>div {
      padding: 0 0.5rem;
      font-size: 12px;

      &:nth-child(1) {
        width: 10%;
      }

      &:nth-child(2) {
        width: 70%;
      }

      &:nth-child(3) {
        width: 14%;
      }

      &:nth-child(4) {
        width: 6%;
      }
    }

    .material-symbols-outlined {
      font-size: 24px;
      color: $light-blue;
    }
  }
}

.form-document {
  &.ng-invalid {
    &.ng-dirty {
      p-button {
        top: 73px;
      }
    }
  }

  &.hide-choose-btn {
    .p-fileupload.custom-document {
      .p-fileupload-buttonbar {
        display: none;
      }
    }
  }

  &.no-file-selected {
    .p-fileupload {
      &.custom-document {
        border-color: $danger;
      }
    }
  }
}