label {
    color: $black; //old $label
    font-size: .625rem;
    margin-bottom: .3125rem;
    font-weight: 500;
}

.form-control {
    border-color: $lightest-gray;

    &:hover {
        border-color: $secondary;
    }

    &:focus {
        border-color: $secondary;
        box-shadow: none;
    }

    &.ng-invalid {
        &.ng-dirty {
            border-color: $danger !important;
            background-color: $alert-errorlight;
        }
    }
}

//selecy=t dropdown icon
.icon-dropdown {
    position: absolute;
    right: 5px;
    bottom: 1px;
    color: $light-gray;
    pointer-events: none;
    z-index: 1;
}

.dropdown-toggle {
    &::after {
        content: "\e5cf";
        font-family: 'Material Symbols Outlined';
        font-weight: 300;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
        border: 0;
        color: $dark-gray;
    }
}

select {
    &.form-control {
        select {
            padding-right: 2rem;
        }
    }
}

.badge {
    &.badge-status-truck-match {
        position: relative;
        border: none;
        background-color: $white;
        color: $white;
        padding: .1875rem .4rem .19rem;
        border-radius: 20px;
        font-size: .75rem;
        font-weight: normal;

        &::after {
            display: none;
        }
    }

    &.badge-status {
        position: relative;
        border: 1px solid $light-gray !important;
        background-color: $white !important;
        color: $body-color !important;
        padding: .1875rem .4rem .19rem 1.25rem;
        border-radius: 20px;
        font-size: .75rem;
        font-weight: normal;

        &.badge-status-text {
            padding-left: .4rem;

            &::after {
                display: none;
            }
        }

        &.badge-status-tracking-board {
            padding: .1875rem .4rem .19rem 0.4rem;

            &::after {
                display: none;
            }
        }

        &.badge-status-check {
            background-color: transparent;

            &::after {
                display: none;
            }

            &::before {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                content: '';
                font-family: "Material Symbols Outlined";
                content: "\e5ca";
                font-size: 18px;
                color: $white;
                background: $primary;
                border-radius: 20px;
            }
        }

        &::after {
            position: absolute;
            left: 8px;
            top: 6px;
            bottom: 0;
            content: '';
            height: 7px;
            width: 7px;
            border-radius: 7px;
            background-color: $primary;
        }

        &.text-bg-danger {
            &::after {
                background-color: $danger;
            }
        }

        &.text-bg-success {
            &::after {
                background-color: $success;
            }
        }

        &.text-bg-draft,
        &.text-bg-newdraft {
            &::after {
                background-color: $draft;
            }
        }

        &.text-bg-open,
        &.text-bg-active {
            &::after {
                background-color: $open;
            }
        }

        &.text-bg-waterfall {
            &::after {
                background-color: $waterfall;
            }
        }

        &.text-bg-assigned {
            &::after {
                background-color: $assigned;
            }
        }

        &.text-bg-cover {
            &::after {
                background-color: $cover;
            }
        }

        &.text-bg-invited {
            &::after {
                background-color: $loaded;
            }
        }

        &.text-bg-dispatch,
        &.text-bg-dormant {
            &::after {
                background-color: $dispatch;
            }
        }

        &.text-bg-pickup {
            &::after {
                background-color: $pickup;
            }
        }

        &.text-bg-loaded {
            &::after {
                background-color: $loaded;
            }
        }

        &.text-bg-onhold {
            &::after {
                background-color: $onhold;
            }
        }

        &.text-bg-transit {
            &::after {
                background-color: $transit;
            }
        }

        &.text-bg-creditrequested {
            &::after {
                background-color: $released;
            }
        }

        &.text-bg-destination {
            &::after {
                background-color: $destination;
            }
        }

        &.text-bg-delivered {
            &::after {
                background-color: $delivered;
            }
        }

        &.text-bg-inreview {
            &::after {
                background-color: $transit;
            }
        }

        &.text-bg-billing {
            &::after {
                background-color: $billing;
            }
        }

        &.text-bg-closed {
            &::after {
                background-color: $closed;
            }
        }

        &.text-bg-pending {
            &::after {
                background-color: $warning;
            }
        }

        &.text-bg-approved {
            &::after {
                background-color: $success;
            }
        }

        &.text-bg-rejected {
            &::after {
                background-color: $danger;
            }
        }

        &.text-bg-donotuse {
            &::after {
                background-color: $danger;
            }
        }

        &.text-bg-credithold {
            &::after {
                background-color: $loaded;
            }
        }

        &.text-bg-new {
            &::after {
                background-color: $draft;
            }
        }

        &.text-bg-noncompliant {
            &::after {
                background-color: $dispatch;
            }
        }

        &.text-bg-load1 {
            &::after {
                background-color: $draft;
            }
        }

        &.text-bg-load2,
        &.text-bg-reserved {
            &::after {
                background-color: $reserved;
            }
        }

        &.text-bg-load3 {
            &::after {
                background-color: $open;
            }
        }

        &.text-bg-yellow {
            &::after {
                background-color: $yellow;
            }
        }

        &.text-bg-gray {
            &::after {
                background-color: $gray;
            }
        }

        &.bg-highlight {
            background-color: $highlight !important;
        }
    }

    &.text-bg-accounting {
        color: $white !important;
        background-color: $onhold !important;
    }

    &.text-bg-general {
        color: $white !important;
        background-color: $info !important;
    }

    &.text-bg-crm {
        color: $white !important;
        background-color: $cover !important;
    }

    &.text-bg-operations {
        color: $white !important;
        background-color: $dark-gray !important;
    }

    &.text-bg-sales {
        color: $white !important;
        background-color: $released !important;
    }

    &.text-bg-compliance {
        color: $white !important;
        background-color: $orange !important;
    }

    &.text-bg-procurement {
        color: $white !important;
        background-color: $dispatch !important;
    }

    &.text-bg-appointments {
        color: $white !important;
        background-color: $pickup !important;
    }

    &.text-bg-carrier {
        color: $white !important;
        background-color: $reserved !important;
    }

    &.text-bg-claims {
        color: $white !important;
        background-color: $warning !important;
    }

    &.text-bg-customer {
        color: $white !important;
        background-color: $secondary !important;
    }

    &.text-bg-transit {
        color: $white !important;
        background-color: $transit !important;
    }

    &.text-bg-osd {
        color: $white !important;
        background-color: $pickup !important;
    }

}

// TDOD: Need to move in a separate file
.p-autocomplete {
    display: flex !important;
    width: 100%;

    .p-overlay {
        width: 100%;
    }

    .p-autocomplete-item {
        white-space: normal;
    }
}

.p-autocomplete-loader {
    display: none;
}

p-autocomplete.ng-dirty.ng-invalid>.p-autocomplete>.p-inputtext {
    border-color: #F54162;
    background-color: #f7cfd3;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-family: "Montserrat", Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 10px;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
    padding: 0 0.5rem;
    background-color: $lightest-gray;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
    padding: 0.15rem 0;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
    margin-left: 0.25rem;
    top: 1px;
    position: relative;
}

.btn-outline-primary {

    &:not(.active) {

        &.btn:hover,
        &.btn:focus:hover {
            color: $white;
            background-color: $primary;
            border-color: $primary;
        }
    }

    &:not(.active, .border-dotted) {

        &.btn:active,
        &.btn:focus {
            color: $primary;
            background-color: $white;
            border-color: $primary;
        }
    }

    &.btn:focus {
        box-shadow: none;
    }

    &.border-dotted {

        &.btn:hover,
        &.btn:focus:hover {
            color: $black;
        }
    }
}

.btn-outline-secondary {

    &.btn:hover,
    &.btn:focus:hover {
        color: $white;
        background-color: $secondary;
        border-color: $secondary;
    }

    &:not(.active) {

        &.btn:active,
        &.btn:focus {
            color: $secondary;
            background-color: $white;
            border-color: $secondary;
        }
    }

    &.btn:focus {
        box-shadow: none;
    }

    &.active {
        color: $white;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.form-control {
    &:disabled {
        background-color: #F4F7F9;

        &:hover {
            border-color: #EBEBEB;
        }
    }
}

p-multiselect,
p-autocomplete,
p-inputmask,
p-inputnumber,
p-calendar {

    .p-disabled,
    &:disabled {
        background-color: #F4F7F9;
    }
}

.p-dropdown,
.p-multiselect,
.p-autocomplete,
.p-inputnumber,
.p-inputnumber-input {
    &.p-disabled {
        opacity: 1;
        background-color: #F4F7F9;
    }
}

p-inputmask {
    .p-component {
        &:disabled {
            opacity: 1;
            background-color: #F4F7F9;
        }
    }
}

.p-component {
    &:disabled {
        opacity: 1;
    }
}

p-inputtext {
    &:disabled {
        background-color: #F4F7F9 !important;
    }
}

label {
    &.disable {
        opacity: 50%;
    }
}

::-webkit-input-placeholder {
    color: $light-gray;
}

:-moz-placeholder {
    color: $light-gray;
}

::-moz-placeholder {
    color: $light-gray;
}

:-ms-input-placeholder {
    color: $light-gray;
}


.form-control-inputnumber,
.p-calendar {
    .p-inputtext {
        &:disabled {
            background-color: #F4F7F9;
        }
    }
}

.p-inputtext {
    color: $black;
}

.control-miles {
    width: 60px;
}

.badge.badge-status.no-icon {
    padding: 0.1875rem 0.4rem 0.19rem 0.4rem;
}

span.badge.badge-status.no-icon {
    &::after {
        display: none;
    }
}

.currency-input {
    position: relative;
    width: 100%;

    span {
        &.icon-dollar {
            position: absolute;
            top: 8px;
            left: 0;
            padding: 0 0 0 0.5rem;
            font-size: 0.625rem;
            font-weight: 400;
            line-height: 1.5;
        }
    }

    input {
        padding-left: 15px;
    }
}