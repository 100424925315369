.bar-info-wrapper {
    height: 33px;
    gap: 20px;

    .bar-info-detail {
        gap: 20px;

        .child {
            &:last-of-type {
                border-right-width: 0;
            }

            line-height: normal;
            padding: 0 10px 0 0;
            border-right: 1px solid $light-gray;

            &.progress-wrapper {
                font-size: 12px;
                display: flex;
                flex-direction: column;
                color: $light-gray;

                .progress-bar {
                    width: 200px;
                }

                h5 {
                    color: $light-gray;
                    margin-bottom: 0;
                    font-weight: 500;
                    line-height: normal;
                }
            }

            &.child-center {
                display: flex;
                align-items: center;
            }
        }
    }

    h2 {
        color: $dark-gray;

    }
}