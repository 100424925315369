.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #11797D;
    background: $white;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    width: 10px;
    height: 10px;
    background-color: $primary;
}

.p-radiobutton .p-radiobutton-box {
    border: 1px solid $primary;
}