.p-button {
    background: $primary;
    border: 1px solid $primary;
    padding: .3125rem .625rem;
    border-radius: 0;

    &:enabled {
        &:hover {
            background: darken($color: $primary, $amount: 5);
            border-color: darken($color: $primary, $amount: 5);
        }
    }

    &:focus {
        box-shadow: 0 0 0 0.25rem rgba(53, 141, 154, 0.5);
    }

    &.p-confirm-dialog-reject {
        background-color: transparent;
        color: $primary;
    }
}

.p-button:focus {
    box-shadow: none;
}

.p-button .p-button-label {
    transition-duration: 0s;
}

.p-button .p-icon {
    width: 12px;
    height: 12px;
}