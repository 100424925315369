.p-panel .p-panel-header .p-panel-header-icon:focus {
    box-shadow: none;
}

.panel-dotted,
.accordion-dotted {

    &.accordion-dotted-sm {

        &.p-accordion .p-accordion-header .p-accordion-header-link {
            padding: 10px;
        }
    }

    &.p-panel,
    &.p-accordion {

        .p-panel-header,
        .p-accordion-header {
            border: 1px dotted $primary;
            border-bottom: none;
            border-top-right-radius: .625rem;
            border-top-left-radius: .625rem;
            background-color: $white;
            transition: 0.35s all ease-in-out;
        }

        .p-panel-content,
        .p-accordion-content {
            padding: 1rem;
            border: 1px dotted $primary;
            border-bottom-right-radius: .625rem;
            border-bottom-left-radius: .625rem;
            border-top: 0 none;
        }

        .p-accordion-content {
            padding-top: 0;
        }

        .p-panel,
        .p-accordion {

            &.p-panel-toggleable,
            &.p-accordion-tab {

                &:not(.p-panel-expanded),
                &:not(.p-accordion-tab-active) {

                    .p-accordion-header {
                        border-bottom-right-radius: .625rem;
                        border-bottom-left-radius: .625rem;
                        border: 1px dotted $primary;
                    }
                }

                &:not(.p-panel-expanded) {
                    .p-panel-header {
                        border-bottom-right-radius: .625rem;
                        border-bottom-left-radius: .625rem;
                        border: 1px dotted $primary;
                    }
                }
            }

        }

    }

    &.p-panel-toggleable {
        .p-panel-header {
            border-bottom-right-radius: .625rem;
            border-bottom-left-radius: .625rem;
        }
    }

    &.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link,
    &.p-accordion .p-accordion-header .p-accordion-header-link {
        background: transparent;
        border-bottom: none;
    }

    &.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
        background: transparent;
        border-color: transparent;
        color: #495057;
    }

    &.p-accordion:not(.carrier-loadBoard) p-accordiontab .p-accordion-tab {
        margin-bottom: 1.25rem;
    }

    &.p-accordion {
        .p-accordion-tab {
            &:not(.p-accordion-tab-active) {
                .p-accordion-header {
                    border-bottom: 1px dotted $primary;
                    border-bottom-right-radius: .625rem;
                    border-bottom-left-radius: .625rem;
                }
            }
        }
    }

    &.p-accordion {
        .p-accordion-header-link {
            position: relative;

            .p-icon-wrapper {
                position: absolute;
                right: 0;
            }
        }
    }
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: none;
}

.grid-overlay {
    .p-overlaypanel-content {
        padding: 0;
        width: 245px;
    }

    &.p-overlaypanel {
        .p-overlaypanel-close {
            color: #495057;
            border-color: transparent;
            background: transparent;
            width: 1.5rem;
            height: 1.5rem;
            right: 10px;
            top: 10px;

            &:hover {
                background: #e9ecef;
                color: #495057;
            }

            &:focus {
                box-shadow: none;
            }

            .p-icon {
                width: 12px;
                height: 12px;
            }
        }
    }
}

.resolve-drop {
    .p-dropdown {
        width: 100%;
    }
}

.panel-role {

    .p-panel,
    .p-toggleable-content {
        .p-panel-content {
            padding-top: 0 !important;
        }
    }
}

.configuration-fleet {
    .p-panel-content {
        padding: 0;
    }
}