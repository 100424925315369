.p-card {
    border-radius: 0;
    border: 1px solid $lightest-gray;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    color: $body-color;

    .p-card-body {
        padding: .625rem; //10px
        border-radius: 0;
    }

    .p-card-content {
        padding: 0;
        border-radius: 0;
    }

    &.card-small {
        .p-card-body {
            padding: .625rem;
        }
    }

    &.card-info {
        .p-card-body {
            background: $grid-color;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
            margin: 0 -12px;
            padding: 8px 1rem;
        }

        &.card-info-carrier {
            .p-card-body {
                background: $white;
                box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
                margin: 0;
                padding: 8px 1rem;
            }
        }
    }

    &.card-no-padding {
        .p-card-body {
            padding: 0;

            .p-card-body {
                padding: 10px;
            }
        }
    }

    &.card-no-shadow {
        box-shadow: none !important;
        border: 0;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            border-top: 2px solid #fff;
        }

        ag-ltr .ag-cell {
            border-right-width: 0;
        }
    }

    &.card-basic {
        border: 1px solid $lightest-gray;
        box-shadow: none;

        .p-card-body {
            padding: 1rem !important;
        }
    }

    &.card-carrier-profile {
        min-height: 233px; //old 246px 
    }
}

.contact-list-container {
    min-width: 0;
    flex: 1;
    flex-basis: 0;
    position: relative;

    app-checkbox-control {
        label {
            color: $body-color;
            font-size: .75rem;
            font-weight: 400;
        }
    }

    .contact-list-content {
        width: 31.5% !important;
        max-width: 270px !important; // changed after horizontal tabs conversion from 209px !important;
        gap: 0.1875rem;

        &:not(:last-child) {
            padding-right: 1.125rem;
            margin-right: 1.125rem;
            border-right: 1px solid $lightest-gray;
            display: flex;
            flex-direction: column;

            p,
            a {
                margin: 0;
                display: flex;
                align-items: center;

                .material-symbols-outlined {
                    text-decoration: none;
                }
            }
        }

        &:nth-child(4) {
            justify-content: space-around;
        }

        &:last-child {
            position: absolute;
            bottom: 10px;
            right: 10px;
            width: 10% !important;
            max-width: 10% !important;

            .btn {
                .material-symbols-outlined {
                    font-size: 22px;
                }
            }
        }

        .remember-checkbox {
            label {
                margin-bottom: 0;
            }
        }
    }

    .facility-contact-list-content {
        width: 31.5% !important;
        max-width: 260px !important; // changed after horizontal tabs conversion from 209px !important;
        gap: 0.1875rem;

        &:not(:last-child) {
            padding-right: 1.125rem;
            margin-right: 1.125rem;
            border-right: 1px solid $lightest-gray;
            display: flex;
            flex-direction: column;

            p,
            a {
                margin: 0;
                display: flex;
                align-items: center;

                .material-symbols-outlined {
                    text-decoration: none;
                }
            }
        }

        &:nth-child(4) {
            justify-content: space-around;
        }

        &:last-child {
            display: flex;
            align-items: center;
            justify-content: center;

            .btn {
                .material-symbols-outlined {
                    font-size: 22px;
                }
            }
        }

        .remember-checkbox {
            label {
                margin-bottom: 0;
            }
        }
    }
}

.col-side {
    width: 455px;
    min-width: 455px;
    max-width: 455px;
}

.overlay-panel-user-progress {
    &.p-overlaypanel {
        background-color: transparent;
        border: none;
        border-radius: 1.25rem;

        &:after,
        &:before {
            display: none;
        }

        .p-overlaypanel-content {
            padding: 0;
            width: 740px;
        }
    }
}

.overlay-panel-carrier-scorecard {
    &.p-overlaypanel {
        padding: 1rem;

        &:after,
        &:before {
            display: none;
        }

        .p-overlaypanel-content {
            padding: 0;
        }
    }
}


.card {
    &.card-body-color {
        background: $body-bg;
        border: none;
        border-radius: .3125rem;
        padding: .625rem;
    }

    &.card-user-progress {
        background-color: #7EE0E0;
        border-radius: 1.25rem;
        border: none;

        .initial {
            background: $primary;
            height: 38px;
            width: 38px;
            text-align: center;
            line-height: 38px;
            border-radius: 38px;
            color: $white;
        }

        .circle {
            border: 3px solid $open;
            height: 47px;
            width: 47px;
            text-align: center;
            line-height: 42px;
            border-radius: 47px;
            font-weight: 500;
        }

        .card {
            border: none;
            padding: .625rem;
        }

    }
}

.card {
    &.card-carrier {
        background: $white;
        border: 1px solid rgba(117, 189, 166, 0.50);
        cursor: pointer;
        transition: 0.25s all ease;

        &:not(.active) {
            &:hover {
                border: 1px solid rgba(117, 189, 166, 1);
            }
        }

        &.active {
            background: $lightest-gray;
            border: 1px solid rgba(117, 189, 166, 0);
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: -1px;
                bottom: -1px;
                right: -16px;
                width: 16px;
                background-color: $lightest-gray;
                z-index: 1;
            }
        }

        a {
            width: fit-content;
        }
    }
}

.card {
    &.card-notes {
        padding: 10px;
        border-radius: 0;
        background-color: $white;
        border: 1px solid rgba(143, 143, 143, 0.30);
    }
}


.card-do-not-use {
    .p-card {
        box-shadow: none;
        background-color: transparent;

        &.card-info {
            .p-card-body {
                background: rgba(245, 65, 98, 0.15);
            }
        }

    }
}

.cards-wrapper {
    p-card {
        &:not(:last-child) {
            .card-small {
                margin-bottom: .625rem;
            }
        }
    }
}

.overlay-panel-sync {
    &.p-overlaypanel {
        background-color: $white;
        border: none;
        border-radius: 0;
        margin-top: 16px;
        
        &:after,
        &:before {
            display: none;
        }

        .p-overlaypanel-content {
            padding: 0;
            width: 245px;
        }

        .p-overlaypanel-close {
            color: #495057;
            border-color: transparent;
            background: transparent;
            width: 1.5rem;
            height: 1.5rem;
            right: 10px;
            top: 10px;
            &:hover {
                background: #e9ecef;
                color: #495057;
            }
            &:focus {
                box-shadow: none;
            }
            .p-icon {
                width: 12px;
                height: 12px;
            }
        }
    }

    &.overlay-panel-queue {
        &.p-overlaypanel {
            .p-overlaypanel-content {
                width: 350px;
            }
        }
    }
}
.card-info {
    &.card-info-highlight{     
        .p-card{
            .p-card-body {
                background: $highlight!important;
            }
        }
    }
}