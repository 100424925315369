html {
    font-size: 16px; //1rem
}

body {
    font-size: 12px;

    @include mq(tablet) {
        font-size: 14px;
    }
}

.material-symbols-outlined {
    font-size: 20px;
    font-weight: 300;
    vertical-align: middle;
}

.btn {
    .material-symbols-outlined {
        font-size: 20px;
        vertical-align: middle;
        line-height: 0;
    }
}

.asterisk {
    position: relative;

    &::after {
        // font-family: "Font Awesome 5 Free";
        font-weight: 600;
        content: "*";
        font-size: 14px;
        //vertical-align: top;
        margin-left: 3px;
        color: #ff5050;
        font-style: normal;
        line-height: normal;
        position: absolute;
        left: 2px;
        top: -2px;
    }
}