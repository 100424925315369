// sass/scss variables

// default blue theme #255bc3
[data-color="theme-default"] {
    --color: 268, 68%;
    --l: 45%;
    --primary: hsl(var(--color), var(--l));
    --secondary: #aee2fc;
    --secondary-dark: #98c8e0;
    --primary-dark: hsl(var(--color), calc(var(--l) - 10%));
}

//green theme #7eb93f
[data-color="theme-green"] {
    --color: 89, 49%;
    --l: 49%;
    --primary: hsl(var(--color), var(--l));
    --secondary: #aee2fc;
    --secondary-dark: #98c8e0;
    --primary-dark: hsl(var(--color), calc(var(--l) - 10%));
}

//blush theme #ed5782
[data-color="theme-blush"] {
    --color: 343, 81%;
    --l: 61%;
    --primary: hsl(var(--color), var(--l));
    --secondary: #aee2fc;
    --secondary-dark: #98c8e0;
    --primary-dark: hsl(var(--color), calc(var(--l) - 10%));
}

//cyan theme #00bdaa
[data-color="theme-cyan"] {
    --color: 174, 100%;
    --l: 37%;
    --primary: hsl(var(--color), var(--l));
    --secondary: #aee2fc;
    --secondary-dark: #98c8e0;
    --primary-dark: hsl(var(--color), calc(var(--l) - 10%));
}

// font family
$font-family-sans-serif: "Montserrat", Helvetica, sans-serif,
    // Emoji fonts
    "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

// colors

// color variable overrides
$body-bg: #F1F1F1;
$body-color: #000000; //old origiinal #212529;
$primary: #11797D;
$secondary: #3EA7AB;
$success: #5CB284;
$danger: #F54162;
$warning: #AB4400;
$info: #69D3D6;
$light: #EBEBEB;
$dark: #424242;

// custom colors
$white: #fff;
$black: #000;
$gray: #b1b1b1;
$light-gray: #8F8F8F;
$lightest-gray: #EBEBEB;
$dark-gray: #333333;
$grid-color: #F4F7F9;
$label: #515151;
$onhold: #646793;
$released: #B56CD1;
$grid: #F4F7F9;
$yellow: #e1e812;
$blue: blue;
$orange: orange;

$draft: #AEACAC;
$open: #5CB284;
$waterfall: orange;
$assigned: #e1e812;
$cover: #4287B9;
$dispatch: #C16F92;
$pickup: #5D8964;
$loaded: #C6A25B;
$transit: #FF6A4A;
$destination: #B56CD1;
$delivered: #FF4C73;
$billing: blue;
$closed: #AB4400;
$reserved: #FF8A27;
$light-blue: #7EE0E0;
$highlight: #fff3CE;
$pagination: #D3F1F2;
$zoom: #0b5cff;
$late: #FFDCE4;

//darker variants
$danger-dark: darken($danger, 20%);
$open-dark: darken($open, 20%);
$draft-dark: darken($draft, 20%);
$transit-dark: darken($transit, 20%);
$onhold-dark: darken($onhold, 20%);
$released-dark: darken($released, 20%);
$loaded-dark: darken($loaded, 20%);

// color map
$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
    "released": $released,
    "gray": $gray,
    "yellow": $yellow,
    "blue": $blue,
    "orange": $orange,
    "draft":$draft,
    "open":$open,
    "waterfall":$waterfall,
    "assigned":$assigned,
    "cover":$cover,
    "dispatch":$dispatch,
    "pickup":$pickup,
    "loaded":$loaded,
    "transit":$transit,
    "destination":$destination,
    "delivered":$delivered,
    "billing":$billing,
    "closed":$closed,
    "reserved":$reserved,
    "onhold":$onhold,
    "lightgray":$light-gray,
    "grid":$grid,
    "lightestgray":$lightest-gray,
    "body":$body-bg,
    "danger-dark": $danger-dark,
    "open-dark": $open-dark,
    "draft-dark": $draft-dark,
    "transit-dark": $transit-dark,
    "hold-dark": $onhold-dark,
    "released-dark": $released-dark,
    "loaded-dark": $loaded-dark,
    "white":$white,
    "highlight":$highlight,
    "pagination":$pagination,
    "zoom":$zoom,
    "late":$late,
    "darkgray": $dark-gray
);
$menu-link: #56E9EF;

// columns & gutters
$spacer: .75rem;
$grid-columns: 12;
$grid-gutter-width: .625rem;
$grid-row-columns: 6;
$grid-gutter-width: .625rem;
$gutters: (
    0: 0,
    1: $spacer * .25,
    2: .625rem,
    3: $spacer,
    4: 1.25rem,
    5: $spacer * 3,
);

.ag-theme-material {
    --ag-selected-row-background-color: #3ea7ab40 !important;
}

// buttons
$btn-padding-x: .625rem;
$btn-padding-y: .266rem;
$input-btn-font-size: 0.8125rem;
$btn-font-weight: 400;
$btn-border-radius: 0;
$btn-radius: 0;
$btn-focus-width: 1px;
//$btn-focus-box-shadow:        $input-btn-focus-box-shadow;
$input-btn-focus-box-shadow: none;

// input
$input-padding-x: .5625rem;
$input-padding-y: 0.41rem;
$input-border-radius: 0;
$input-bg: $white;
$input-font-size: .625rem;

// form label (not working somehow)
$form-label-margin-bottom: .3125rem;
$form-label-font-size: .625rem;
$form-label-color: $label;

// color alerts
$alert-error: #d22e28;
$alert-errorlight: #f7cfd3;
$alert-errordark: #691112;
$alert-info: #3f90ca;
$alert-infolight: #bde4f9;
$alert-infodark: #1c4865;
$alert-warning: #d2a437;
$alert-warninglight: #fcecba;
$alert-warningdark: #695117;
$alert-success: #59914f;
$alert-successlight: #cee5cb;
$alert-successdark: #2c4927;

// headings
$headings-font-weight: 600;
$h1-font-size: 1.25rem; //20
$h2-font-size: 1.125rem; //18
$h3-font-size: 1rem; //16
$h4-font-size: .875rem; //14
$h5-font-size: .75rem; //12
//$h6-font-size: $font-size-base;
$headings-color: $body-color;

// utilities
$enable-negative-margins: true