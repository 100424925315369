.randomize-wrapper {
    font-family: "Caveat", cursive !important;
    letter-spacing: 1px;
    font-size: 10rem;
    color: #3d72a3;
}

.img-404-logo {
    max-height: 1.5rem;
}

.spinny-cogs {
    display: block;
    margin-bottom: 2rem;

    .pi {
        &:nth-of-type(1) {
            @extend .pi-spin-one;
        }

        &:nth-of-type(3) {
            @extend .pi-spin-two;
        }
    }

    .pi-5x {
        font-size: 5em;
    }

    .pi-3x {
        font-size: 3em;
    }

    .pi-2x {
        font-size: 1.8em;
    }
}

@-webkit-keyframes pi-spin-one {
    0% {
        -webkit-transform: translateY(-2rem) rotate(0deg);
        transform: translateY(-2rem) rotate(0deg);
    }

    100% {
        -webkit-transform: translateY(-2rem) rotate(-359deg);
        transform: translateY(-2rem) rotate(-359deg);
    }
}

@keyframes pi-spin-one {
    0% {
        -webkit-transform: translateY(-2rem) rotate(0deg);
        transform: translateY(-2rem) rotate(0deg);
    }

    100% {
        -webkit-transform: translateY(-2rem) rotate(-359deg);
        transform: translateY(-2rem) rotate(-359deg);
    }
}

.pi-spin-one {
    -webkit-animation: pi-spin-one 1s infinite linear;
    animation: pi-spin-one 1s infinite linear;
}

@-webkit-keyframes pi-spin-two {
    0% {
        -webkit-transform: translateY(-0.5rem) translateY(1rem) rotate(0deg);
        transform: translateY(-0.5rem) translateY(1rem) rotate(0deg);
    }

    100% {
        -webkit-transform: translateY(-0.5rem) translateY(1rem) rotate(-359deg);
        transform: translateY(-0.5rem) translateY(1rem) rotate(-359deg);
    }
}

@keyframes pi-spin-two {
    0% {
        -webkit-transform: translateY(-0.5rem) translateY(1rem) rotate(0deg);
        transform: translateY(-0.5rem) translateY(1rem) rotate(0deg);
    }

    100% {
        -webkit-transform: translateY(-0.5rem) translateY(1rem) rotate(-359deg);
        transform: translateY(-0.5rem) translateY(1rem) rotate(-359deg);
    }
}

.pi-spin-two {
    -webkit-animation: pi-spin-two 2s infinite linear;
    animation: pi-spin-two 2s infinite linear;
}

.wrapper-error {
    padding: 20px 32px;
    width: 1161px;
    margin: 0 auto;
    border: 1px solid $lightest-gray;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);

    h1 {
        font-size: 40px;
    }

    h2 {
        font-size: 35px;
    }

    p {
        font-size: 18px;
    }
}