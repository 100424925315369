.p-progressbar {
    &.credit-bar {
        height: 5px;
        border-radius: 8px;

        &.credit-bar-sm {
            height: 3px;
            border-radius: 8px;
            width: 50px;
        }

        .p-progressbar-value {
            background: $danger;
            border-radius: 8px;
        }

        .p-progressbar-label {
            display: none !important;
        }
    }
}

.credit-class-green {
    &.p-progressbar {
        &.credit-bar {
            .p-progressbar-value {
                background: $success;
            }
        }
    }
}

.credit-class-yellow {
    &.p-progressbar {
        &.credit-bar {
            .p-progressbar-value {
                background: $yellow;
            }
        }
    }
}

.credit-class-orange {
    &.p-progressbar {
        &.credit-bar {
            .p-progressbar-value {
                background: $orange;
            }
        }
    }
}

.credit-class-red {
    &.p-progressbar {
        &.credit-bar {
            .p-progressbar-value {
                background: $danger;
            }
        }
    }
}