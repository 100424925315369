app-layout {
    display: flex;
    flex-direction: column;
    // height: 100vh;
}

.content-wrapper {
    margin-left: 100px;
}

.content {
    min-width: 1773px; //old 1778px - changed to remove horizontal scroll on edge

    @include mq("tablet", "min") {
        min-height: calc(100vh - 140px);
    }

    @include mq("tablet") {
        border-radius: 10px;
    }
}

.page-loader {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 10520;
    text-align: center;
    display: flex;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
}

.rate-confirmation-pdf .p-inputtext {
    font-family: inherit !important;
}