.p-component {
    font-family: $font-family-sans-serif;
    font-size: 12px;
}

.p-inputtext {
    font-family: $font-family-sans-serif;
}

.p-hidden {
    display: none !important;
}

.menu-horizontal {
    &.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
        border-color: $primary;
        color: $primary;
    }

    &.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
        outline-offset: 0;
        box-shadow: none;
    }
}

.p-sidebar {
    &.sidebar-tabs {
        top: 71px;
        width: 62.5rem;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        height: calc(100% - 71px);
    }

    &.sidebar-notification {
        top: auto;
        bottom: 0;
        width: 25rem; //400px
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        height: 840px;

        .p-sidebar-header {
            padding: 10px 1rem;

            .p-sidebar-close {
                display: none;
            }
        }

        .p-sidebar-content,
        .p-sidebar-footer {
            padding: 0;
        }

        .notification-icon-wrapper {
            height: 2.8125rem;
            width: 2.8125rem;
            min-width: 2.8125rem;
            line-height: 2.65rem;
            text-align: center;
            border-radius: 2.8125rem;

            .material-symbols-outlined {
                font-size: 1.25rem;
            }

            &.icon-notification-email {
                background-color: #D3F1F2;

                .material-symbols-outlined {
                    color: $primary;
                }
            }

            &.icon-notification-chat {
                background-color: #FFF3CE;

                .material-symbols-outlined {
                    color: $primary;
                }
            }

            &.icon-notification-bell {
                background-color: #FFDCE4;

                .material-symbols-outlined {
                    color: $primary;
                }
            }
        }
    }

    &.sidebar-quickview {
        &.p-sidebar-right {
            width: 62rem;
        }

        .p-sidebar-header {
            padding: 0rem 1rem;
            position: relative;
            background: transparent;
            border: 0;

            +.p-sidebar-content {
                padding-top: 0;
                padding-left: 0;
                padding-bottom: 0;
                padding-right: 0;
            }

            .p-sidebar-close {
                position: absolute;
                top: 4px;
                left: 60rem;
                z-index: 2000;
                border: 1px solid;
                padding: 0;
                width: 16px;
                height: 16px;

                svg {
                    height: 10px;
                    width: 10px;
                }
            }
        }

        .p-sidebar-footer {
            padding: 0;
            display: none;
        }
    }
}

.p-sidebar-mask {
    &.p-component-overlay {
        background-color: rgba(0, 0, 0, 0);
    }
}

.paginator-stop {
    &.p-paginator {
        background: transparent;
        border: none;
        border-width: 0;
        padding: 0;
        border-radius: 0;
    }

    &.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
        background: #11797D;
        border-color: #11797D;
        color: #fff;
    }

    &.p-paginator .p-paginator-pages .p-paginator-page,
    &.p-paginator .p-paginator-first,
    &.p-paginator .p-paginator-prev,
    &.p-paginator .p-paginator-next,
    &.p-paginator .p-paginator-last {
        background-color: #D9D9D9;
        border: 0 none;
        color: #6c757d;
        min-width: 20px;
        height: 20px;
        margin: 0.143rem;
        transition: box-shadow 0.2s;
        border-radius: 0;
        font-size: 12px;
    }

    &.p-paginator .p-paginator-first,
    &.p-paginator .p-paginator-prev,
    &.p-paginator .p-paginator-next,
    &.p-paginator .p-paginator-last {
        border-radius: 20px;
    }

}

.media p {
    margin-bottom: 5px;
    word-break: break-word;
}

.p-virtualscroller-item {
    .p-element {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

.p-toast {
    opacity: 1;
}

.p-toast-top-right {
    top: 30px;
    right: 30px;
}

.reject-edi {
    .p-message.p-message-error .p-message-icon {
        display: none;
    }
}

.icon-cell {
    padding-right: 0 !important;
    text-align: center;
}

.loadNumberValidation {
    &.p-overlaypanel {
        position: absolute;
        margin-top: 10px;
        top: 30px;
        left: 850px;
    }

    &.p-overlaypanel:before {
        border-width: 10px;
        margin-left: 0px;
    }

    &.p-overlaypanel:after {
        border-width: 8px;
        margin-left: 2px;
    }
}

.table {
    &.table-kpi {

        > :not(caption)>*>* {
            background: white;
            font-weight: 500;
            font-size: 10px;
            color: $dark-gray;
        }

        tbody {
            tr {
                td {
                    span {
                        padding: 5px;
                        border-radius: 6px;
                        background-color: $lightest-gray;
                    }
                }
            }
        }
    }
}

// box shadow when the check box is chekced and hovered
.p-checkbox {
    &.p-checkbox-checked {
        &.p-checkbox-focused {
            box-shadow: 0 0 0 1px $secondary;
        }
    }
}

// carouasel
.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
    width: 1.25rem;
    height: 1.25rem;
    padding: 10px;
    margin: 0;

    .p-icon {
        width: 12px;
        height: 12px;
    }
}

.calender-icon {
    position: relative;

    &.p-calendar-w-btn .p-datepicker-trigger {
        background: transparent;
        border: 0px solid $lightest-gray;
        border-left: 0;
        color: $light-gray;
        position: absolute;
        right: 0;
        pointer-events: none;
    }
}