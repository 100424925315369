.fw-semibold {
    font-weight: 600 !important;
}

.smaller {
    font-size: 0.75rem !important; //12px
}

.medium {
    font-size: 14px !important;
}

.tiny {
    font-size: .625rem !important; //10px
}

.nano {
    font-size: 8px !important; //8px
}

.custom-label {
    font-size: .625rem !important; //10px
    font-weight: 500;
}

.btn-secondary {
    color: $white;

    &:hover,
    &:active,
    &:focus {
        color: $white;
    }
}

.no-label {
    label {
        display: none !important;
    }
}

.label-side {
    label {
        color: $body-color;
    }
}

.profile-reference {
    .hide-in-profile {
        display: none;
    }

    .tweak-in-profile {
        height: 122px;
        overflow-y: auto;
        flex-direction: column;

        .row {
            margin: 0;
        }

        .col-md-4 {
            width: 100%;

            &.row {
                padding: 0;
                margin: 0 -5px;
            }
        }
    }
}

.lh05 {
    line-height: 0.25;
}

.btn {
    &.btn-xs {
        padding: 0 4px;
        border-radius: 0;
    }

    &.btn-tiny {
        padding: 0;
        height: 1.25rem;
        width: 1.25rem;

        &.btn-rounded {
            border-radius: 50%;
            line-height: 15px;

            .material-symbols-outlined {
                font-size: 15px;
            }
        }
    }

    &.btn-delete-big {
        height: 52px;
        width: 52px;
    }

    &.btn-outline-info,
    &.btn-outline-loaded,
    &.btn-outline-open,
    &.btn-outline-lightgray,
    &.btn-outline-danger {
        &:not(.border-dotted) {

            &:hover,
            &.active {
                color: white;
            }
        }
    }
}

.stop-wrapper {
    .col-md-6 {
        &.border-on {
            &:nth-child(even) {
                border-right: 1px solid #ccc;
                padding-right: 16px;
            }

            &:nth-child(odd) {
                border-left: none;
                padding-left: 16px;
            }
        }

        &.border-on-sm {
            &:nth-child(even) {
                padding-left: 10px;
            }

            &:nth-child(odd) {
                border-right: 1px solid #ccc;
                padding-right: 10px;
            }
        }
    }
}

.ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}

.row {
    &.row-carrier-mc {
        .col {
            width: calc(100% / 7);
        }
    }
}

.rate-confirmation-pdf .p-inputtext::placeholder {
    color: rgba(33, 37, 41, 0.75);
}

.rate-confirmation-pdf .p-inputtext {
    color: rgb(33, 37, 41);
}

.lt-bg {
    padding: 20px;
    margin-top: -20px;
    margin-left: -19px;
    margin-right: -19px;
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
}

.status-list {
    position: relative;
    margin: 0;
    padding: 0;

    &::before {
        content: '';
        height: 6px;
        width: 1px;
        background: $white;
        position: absolute;
        top: 0;
        left: 5px;
        z-index: 1;
    }

    &.status-list-queue {
        li {
            margin-top: -3px;
            justify-content: start;

            &::before {
                top: 10px;
            }

            &:last-child {
                &::before {
                    height: 0;
                }
            }
        }
    }

    li {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        padding-left: 20px;
        overflow: hidden;

        &:last-child {
            &::before {
                height: 18px;
            }
        }

        &.disabled {

            &::after,
            &::before {
                background: $lightest-gray;
            }
        }

        &::after {
            content: '';
            height: 11px;
            width: 11px;
            background: $info;
            border-radius: 11px;
            position: absolute;
            left: 0;
        }


        &::before {
            content: '';
            height: 500px;
            width: 1px;
            background: $info;
            position: absolute;
            top: 0;
            left: 5px;
        }


        ul {
            width: 65%;
            min-width: 65%;
            border-left: 1px solid $lightest-gray;

            li {
                display: list-item;
                padding-bottom: 5px;
                list-style-type: disc;
                overflow: visible;
                padding-left: 0;

                &::after,
                &::before {
                    display: none;
                }
            }
        }
    }
}

.bg-highlight .p-inputtext {
    background-color: $highlight !important;
}

.bg-highlight .p-inputtext:disabled {
    background-color: $highlight !important;
}

.audit-log-info-msg {
    border: 1px dashed #7EE0E0;
    background-color: #F0F9FF;
    border-radius: 3px;
    margin-bottom: 7px;
    text-align: center;
    padding: 5px;
}


.p-messages {
    &.msg-loaded {
        .p-message-wrapper {
            flex-direction: column;
            align-items: start;
            padding-left: 55px;
            position: relative;
        }

        .p-message {
            &.p-message-warn {
                background: $white;
                border: 1px solid $loaded;
                border-width: 1px;
                color: $loaded;
                margin: 10px 0;
            }

            .p-message-detail {
                margin-left: 0;
            }

            .p-message-summary {
                font-weight: 500;
                font-size: 16px;
            }
        }

        .p-message-icon {
            color: $loaded;
            position: absolute;
            left: 16px;
            top: 50%;
            transform: translateY(-50%);

            .p-icon-wrapper {
                display: none;
            }

            &::after {
                font-family: "Material Symbols Outlined";
                content: "\e160";
                font-weight: 200;
            }
        }
    }
}

.w-90 {
    width: 90px !important;
}

.w-645 {
    width: 645px;
    min-width: 645px;
}

.w-170 {
    width: 170px;
}

.custom-info {
    &.p-toast {
        width: 400px;

        .p-toast-message {
            &.p-toast-message-warn {
                background: $white;
                border: none;
                border-width: 0 0 0 0;
                color: $body-color;

                .material-symbols-outlined {
                    font-size: 1.875rem;
                }
            }

            .p-toast-message-content {
                .p-toast-message-icon {
                    color: $info;
                }
            }

            .p-toast-icon-close {
                width: 11px;
                height: 11px;
                min-width: 11px;
                min-height: 11px;

                .p-icon {
                    color: $info;
                }
            }
        }
    }
}

.mh-153 {
    min-height: 153px !important;
}

.text-11 {
    font-size: 11px !important;
}

.w-150 {
    width: 90px !important;
}

.w-188 {
    width: 268px !important;
}

.w-63 {
    width: 63px !important;
}

.w-70 {
    width: 70px !important;
}

.w-130 {
    width: 130px !important;
}

.w-73 {
    width: 50px !important;
}

.bold {
    font-weight: bold;
}

.global-search-validation {
    --bs-popover-border-color: #495057;
    --bs-popover-header-bg: #495057;
    --bs-popover-body-color: #ffffff;
    --bs-popover-body-padding-x: 0.5rem;
    --bs-popover-body-padding-y: .15rem;
    --bs-popover-bg: #495057;
    --bs-popover-font-size: 10px;
}

.checkbox-h5 {
    label {
        font-weight: 600;
    }
}

.tiles-kpi {
    min-height: 95px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.hoo-time-wrap {
    .hoo-time {
        min-width: 80px;

        &:not(:last-child) {
            margin-right: 50px;
        }
    }
}

.t-badge {
    font-size: 10px;
    font-weight: 700;
    position: absolute;
    top: 10px;
    left: 15px;
    text-align: center;
    border-radius: 16px;
    color: white;
    padding: 0 5px 0 5px;
}

.disabled-div {
    pointer-events: none;
    /* Disable mouse events */
    opacity: 0.7;
}

.card-body {
    color: $body-color;

    &.card-rates {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        font-size: 11px;
        padding: 0;
        border: none;
        min-height: 42px;

        span {
            font-size: 14px;
            font-weight: 600;
        }
    }
}

.bg-late .p-inputtext {
    background-color: $late !important;
}

.bg-late .p-inputtext:disabled {
    background-color: $late !important;
}