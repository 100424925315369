//p-megamenu
.p-megamenu {
    padding: 0.5rem;
    background: transparent;
    color: #495057;
    border: none;
    border-radius: 0;
}

.p-megamenu .p-megamenu-panel {
    box-shadow: none;
}

.p-megamenu .p-menuitem-link .p-menuitem-icon+.p-menuitem-text {
    display: block;
    color: $white;
}

.p-megamenu.p-megamenu-vertical {
    width: auto;
    padding: 0;
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link {
    padding: 10px 0.325rem;
    color: $menu-link;
    border-radius: 0;
    transition: box-shadow none;
    -webkit-user-select: none;
    user-select: none;
    justify-content: center;
    flex-direction: column;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: $white;
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-icon {
    color: $menu-link;
    margin-right: 0;
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link.active {
    background-color: $secondary !important;
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link.active .p-menuitem-icon {
    color: $white !important;
}

.p-megamenu .p-menuitem-link .p-menuitem-icon {
    color: $white;
    margin-right: 0;
}

.p-megamenu-vertical .p-megamenu-root-list>.p-menuitem>.p-menuitem-link>.p-icon-wrapper {
    display: none;
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled) {
    &.active {
        &:hover {
            background: transparent;
        }
    }
}


.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled, .active):hover,
.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link,
.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled, .active):hover {
    background: $secondary !important;
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled, .active):hover .p-menuitem-icon {
    color: $dark-gray;
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:focus {
    box-shadow: none;
}

.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled, .active):hover .p-menuitem-icon {
    color: $white;
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled, .active):hover .p-menuitem-icon {
    color: $white;
}

.p-megamenu-grid {
    background: $primary;
}

.p-megamenu .p-menuitem-link .p-menuitem-text {
    color: $white;
}

.p-megamenu .p-megamenu-submenu {
    padding: 0;
    width: 10rem; // old 200px
    border: 1px solid $secondary;
}

.p-megamenu-col-2,
.p-megamenu-col-3,
.p-megamenu-col-4,
.p-megamenu-col-6,
.p-megamenu-col-12 {
    padding: 0 !important;
}

.p-megamenu .p-megamenu-submenu-header {
    background-color: transparent;
    display: none;
}

.p-megamenu .p-megamenu-submenu-header span {
    font-weight: 600;
    color: $white;
    text-transform: uppercase;
    font-size: .625rem;
}

.p-megamenu.p-megamenu-vertical .p-megamenu-submenu .p-menuitem {
    border-bottom: 1px solid $secondary;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover,
.p-megamenu .p-menuitem .p-menuitem .p-menuitem-link-active {
    background: $secondary;
    color: $white;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: $white;
}

.megamenu-divider-after {
    margin-top: 1.25rem !important;
}

.megamenu-divider {
    border-bottom: 1px solid $secondary;
    margin: 0;
    padding: .9375rem 0 0 0 !important;
    pointer-events: none;
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: $white;
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-text {
    margin-top: .3125rem;
    color: $white;
}

.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text,
.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: $white;
}

.p-megamenu .p-menuitem-link {
    padding: .875rem .625rem;
}