.p-inputswitch {
    height: .875rem;
    width: 1.875rem;
}

.p-inputswitch .p-inputswitch-slider:before {
    width: .625rem;
    height: .625rem;
    left: .1875rem;
    margin-top: -0.3rem;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(0.825rem);
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: darken($color: $primary, $amount: 5);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: $primary;
    ;
}

.p-inputswitch.p-focus .p-inputswitch-slider {
    box-shadow: none;
}

.switch-pickup {
    &.p-inputswitch-checked {
        .p-inputswitch-slider {
            &:after {
                content: "P";
                top: 0;
                left: 5px;
            }
        }
    }

    .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
        background-color: #117970 !important;
    }

    .p-inputswitch-slider {
        background-color: #117970 !important;

        &:after {
            content: "D";
            position: absolute;
            left: unset;
            right: 5px;
            font-size: 10px;
            color: white;
        }
    }
}

.switch-rates {
    &.p-inputswitch-checked {
        .p-inputswitch-slider {
            &:after {
                content: "R";
                top: 0;
                left: 5px;
                text-align: left;
            }
        }
    }

    .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
        background-color: #117970 !important;
    }

    .p-inputswitch-slider {
        background-color: #117970 !important;

        &:after {
            content: "$";
            position: absolute;
            left: unset;
            right: 5px;
            font-size: 10px;
            color: white;
        }
    }
}