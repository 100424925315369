.page-auth {
    //background-image: url("../../img/bg-login.svg");
    // background-size: cover;
    // background-attachment: fixed;
    background-color: $white;

    @include mq(tablet, min) {
        app-root {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            app-login {
                &:not(.p-toast-message-icon, .p-toast-icon-close, .fas) {
                    width: 100%;
                }
            }
        }
    }
}

.page-auth-captcha {
    .grecaptcha-badge {
        visibility: visible !important;
        display: block !important;
    }
}

.grecaptcha-badge {
    visibility: hidden;
    display: none;
}

.logo-auth {
    max-width: 188px;
}

.img-auth {
    max-width: 220px;
    margin-bottom: 2.5rem;
    //@include center;
}

.login-tagline {
    font-size: 1.1875rem;
    font-weight: normal;
    font-weight: 500;
    color: $dark-gray;

    strong {
        font-weight: 700;
    }
}

.login-text {
    font-size: .8125rem;
    color: $dark-gray;
}

.card-auth {
    //border-radius: 1.25rem;
    // overflow: hidden;
    // max-width: 1028px;
    // box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.25);
    // @include center;

    @include mq(tablet) {
        margin: 16px;
    }

    .card-form {
        //padding: 4.3125rem 6.25rem 1.25rem;

        @include mq(tablet2) {
            //padding: 1rem;
        }

        h2 {
            font-weight: 300;
            color: $dark;
            font-size: 1.25rem;
        }

        p {
            //color: $light;
            font-size: 0.8125rem;
        }

        .p-input-icon-right {
            i {
                color: $gray;
            }
        }

        a {
            text-decoration: none;
        }
    }
}

.google-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-color: #cacaca;
    border-radius: 20px;
    color: $dark-gray;
    height: 42px;
    max-width: 286px;
    margin: 0 auto;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    padding: 10px 20px;

    img {
        width: 25px;
    }
}

.google-btn:hover {
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.3);
}

.google-icon-wrapper {
    margin-right: 10px;
}

.google-icon {
    height: 24px;
    width: 24px;
}

.btn-text {
    display: flex;
    align-items: center;
}

.login-main {
    padding-top: 9.375rem;
    width: 29.6875rem;
    min-width: 29.6875rem;
    padding: 0 3.125rem;
}

.login-slider {
    padding: 1.25rem;
    height: 100vh;

    .login-slider-content {
        padding: 7.5rem 3.25rem;
        background-image: url("../../img/bg-login.png");
        background-position: center;
        background-size: cover;
        border-top-left-radius: 4.6875rem;
        border-bottom-right-radius: 4.6875rem;
        overflow: hidden;

        .img-auth {
            max-width: 17.75rem;
            margin-bottom: 2.5rem;
        }

        .login-tagline {
            font-size: 1.875rem;
        }

        .login-text {
            font-size: 1rem;
        }

    }
}