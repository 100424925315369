.p-checkbox .p-checkbox-box.p-highlight {
    border-color: $primary;
    background: $primary;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: darken($color: $primary, $amount: 5);
    background: darken($color: $primary, $amount: 5);
}

p-checkbox.p-element {
    vertical-align: middle;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: $primary;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    box-shadow: none;
    border-color: $primary;
}

.p-checkbox .p-checkbox-box {
    border-width: 1px !important;
}

.p-checkbox {
    width: 15px;
    height: 15px;
}

.p-checkbox .p-checkbox-box {
    border-width: 1px !important;
    width: 15px;
    height: 15px;
    border-radius: 0;
}

.p-checkbox .p-checkbox-box .p-icon {
    width: 12px;
    height: 12px;
}

.checkbox-big-label {
    margin-top: 20px;

    label {
        font-size: 12px;
    }

    .p-checkbox {
        &.p-component {
            margin-top: -4px;
        }
    }
}

input[type=checkbox] {
    accent-color: $primary;
}