[class*=ag-theme-] {
    font-family: $font-family-sans-serif;
    font-size: 12px;
}

.ag-row {
    color: $black;
    border-bottom-color: $lightest-gray;
}

.ag-header-cell,
.ag-header-group-cell,
.ag-cell,
.ag-full-width-row .ag-cell-wrapper.ag-row-group {
    padding-left: .625rem; //old 20px
    padding-right: .625rem; //old 20px
}

.ag-theme-material .ag-cell-inline-editing {
    padding-top: 0;
    padding-bottom: 0;
    height: 100%;
}

.ag-theme-material .ag-has-focus .ag-cell-inline-editing {
    border-color: #e2e2e2 !important;
}

.ag-theme-material,
.ag-root-wrapper,
.ag-sticky-top,
.ag-dnd-ghost {
    background-color: transparent;
}


.floating-filter {

    &.ag-theme-material .ag-header-row-column-filter input[class^=ag-]:not([type]),
    &.ag-theme-material .ag-header-row-column-filter input[class^=ag-][type=text],
    &.ag-theme-material .ag-header-row-column-filter input[class^=ag-][type=number],
    &.ag-theme-material .ag-header-row-column-filter input[class^=ag-][type=tel],
    &.ag-theme-material .ag-header-row-column-filter input[class^=ag-][type=date],
    &.ag-theme-material .ag-header-row-column-filter input[class^=ag-][type=datetime-local],
    &.ag-theme-material .ag-header-row-column-filter textarea[class^=ag-] {
        padding: 0 5px;
        height: 19px;
    }

}


.ag-theme-material {
    &:not(.floating-filter) {
        .ag-header {
            background-color: transparent;
            border-bottom: 2px solid $secondary;
        }
    }

    &.floating-filter {

        .ag-header {
            background-color: transparent;
        }

        .ag-header-row:not(:first-child) .ag-header-cell:not(.ag-header-span-height.ag-header-span-total),
        .ag-header-row:not(:first-child) .ag-header-group-cell.ag-header-group-cell-with-group {
            background-color: white;
            border-top: 2px solid $secondary;
        }
    }
}

.ag-tab-selected {
    border-bottom-color: $secondary;
}

.ag-filter-apply-panel {
    padding: 12px;
}

.ag-body {
    background-color: $white;
}

.ag-theme-material .ag-filter-toolpanel-header,
.ag-theme-material .ag-filter-toolpanel-search,
.ag-theme-material .ag-status-bar,
.ag-theme-material .ag-header-row,
.ag-theme-material .ag-panel-title-bar-title,
.ag-theme-material .ag-multi-filter-group-title-bar {
    color: $body-color;
}

.ag-icon-menu::before {
    font-family: "Material Symbols Outlined";
    content: "\ef4f"
}

.ag-icon-desc::before {
    font-family: "Material Symbols Outlined";
    content: "\e164"
}

.ag-icon-asc::before {
    font-family: "Material Symbols Outlined";
    content: "\e164"
}

.ag-picker-field-wrapper {
    border-radius: 0;
}

.ag-theme-material input[class^=ag-]:not([type]):focus,
.ag-theme-material input[class^=ag-][type=text]:focus,
.ag-theme-material input[class^=ag-][type=number]:focus,
.ag-theme-material input[class^=ag-][type=tel]:focus,
.ag-theme-material input[class^=ag-][type=date]:focus,
.ag-theme-material input[class^=ag-][type=datetime-local]:focus,
.ag-theme-material textarea[class^=ag-]:focus {
    border-bottom-color: $primary;
}

.ag-theme-material .ag-picker-field-wrapper:focus {
    box-shadow: 0 0 0 1px $primary;
}

.table.table-tabs> :not(h5)>*>* {
    padding: 0;
}

.ag-select .ag-picker-field-wrapper {
    border: 1px solid $lightest-gray;
}

.ag-checkbox-input-wrapper.ag-checked::after {
    color: $primary;
}

.ag-theme-material input[class^=ag-]:not([type]),
.ag-theme-material input[class^=ag-][type=text],
.ag-theme-material input[class^=ag-][type=number],
.ag-theme-material input[class^=ag-][type=tel],
.ag-theme-material input[class^=ag-][type=date],
.ag-theme-material input[class^=ag-][type=datetime-local],
.ag-theme-material textarea[class^=ag-] {
    border: 1px solid $lightest-gray;
    border-bottom-width: 1px;
    padding: 0.41rem 0.5625rem;
    height: 32px;
}

.ag-theme-material input[class^=ag-]:not([type]):focus,
.ag-theme-material input[class^=ag-][type=text]:focus,
.ag-theme-material input[class^=ag-][type=number]:focus,
.ag-theme-material input[class^=ag-][type=tel]:focus,
.ag-theme-material input[class^=ag-][type=date]:focus,
.ag-theme-material input[class^=ag-][type=datetime-local]:focus,
.ag-theme-material textarea[class^=ag-]:focus {
    border-bottom-width: 1px;
    border-bottom-color: #3EA7AB;
    border-color: #3EA7AB;
    padding: 0.41rem 0.5625rem;
}

.ag-checkbox-input-wrapper::after {
    color: $primary;
}

.ag-checkbox-input-wrapper.ag-indeterminate::after {
    color: $primary;
}

.ag-column-select-column-drag-handle {
    display: none
}

.ag-theme-material .ag-standard-button {
    background-color: $primary;
    font-size: 13px;
    color: $white;
    padding: 0.266rem 0.625rem;

    &[type=button] {
        background-color: $white;
        color: $primary;
        border: 1px solid $primary;

        &:hover,
        &:active {
            color: $white;
        }
    }

    &:hover,
    &:active {
        background-color: #0e676a;
    }
}

.ag-paging-panel {
    height: 30px;
}

.ag-ltr .ag-floating-filter-button {
    display: none;
}

.ag-side-bar {
    position: absolute;
    right: 0;
    top: 0;
    height: 0;
}

.ag-column-panel {
    position: absolute;
    background-color: #fff;
    height: 300px;
    width: 245px;
    right: 0;
    top: 7px;
    border: none;
    box-shadow: 0 2px 10px 0 rgba(107, 107, 107, 0.12);
    z-index: 9;

    .ag-column-select,
    .ag-column-select-header {
        border-bottom-color: #f0f0f0;
    }

    .ag-column-panel-column-select {
        border-top: none;
    }

    .ag-column-select-virtual-list-item {
        border-bottom: 1px solid #f4f7f9;
    }
}

input[class^=ag-][type=button]:focus,
button[class^=ag-]:focus {
    &:focus {
        box-shadow: none;
    }
}

.late-exception {
    background-color: #ffdce4 !important;
}

.has-expanded-row {

    .ag-ltr .ag-row-drag,
    .ag-ltr .ag-selection-checkbox,
    .ag-ltr .ag-group-expanded,
    .ag-ltr .ag-group-contracted {
        margin-right: .625rem;
    }

    &.has-expanded-row-checkcall {
        .ag-details-row {
            padding: 0;
            padding-top: .625rem;
            background-color: $white;
        }
    }

    .ag-details-row {
        padding: .625rem;
        background-color: $grid;
    }

    .ag-ltr .ag-row-group-leaf-indent {
        margin-left: 26px;
    }
}

.ag-group-child-count {
    display: none !important;
}


.aging-pending {
    background-color: $gray !important;

    &.ag-row-hover:not(.ag-full-width-row)::before,
    &.ag-row-hover.ag-full-width-row.ag-row-group::before {
        background-color: $gray !important;
    }
}

.aging-failed {
    background-color: lighten($color: $danger, $amount: 25) !important;

    &.ag-row-hover:not(.ag-full-width-row)::before,
    &.ag-row-hover.ag-full-width-row.ag-row-group::before {
        background-color: lighten($color: $danger, $amount: 25) !important;
    }
}

.carrier-loadboard-grid {

    .ag-header-cell,
    .ag-header-group-cell,
    .ag-cell,
    .ag-full-width-row .ag-cell-wrapper.ag-row-group {
        padding-left: 0; //old 20px
        padding-right: 0; //old 20px
        border: none !important;
    }

    &.ag-theme-material:not(.floating-filter) .ag-header {
        border: none !important;
    }
}

.ag-theme-material {
    --ag-cell-horizontal-border: solid #EBEBEB;
    --ag-header-column-separator-display: block;
}

.loadboard-red {
    background-color: #FF0000 !important;
}

.loadboard-yellow {
    background-color: #FFFF00 !important;
}

.loadboard-orange {
    background-color: #FF9900 !important;
}

.loadboard-blue {
    background-color: rgba(66, 133, 240, 0.65) !important;
}

.loadboard-purple {
    background-color: rgba(153, 0, 255, 0.42) !important;
}

.yield-waterfall-primary {
    background-color: rgba(193, 111, 146, 0.40) !important;
}

.yield-header {
    .ag-header-group-cell-label {
        justify-content: center !important;
    }
}