.p-tooltip-text {
    font-size: 10px;
}

.p-tooltip-arrow {
    margin-left: 1px;
}

.p-tooltip {
    &.tooltip-activity {
        .p-tooltip-text {
            font-size: 12px;
            width: 320px;
        }
    }
}