.steps-profile {
    &.p-steps {
        .p-steps-item {
            &:last-child {
                &.active {
                    &::after {
                        content: " ";
                        border-top: 2px solid $lightest-gray;
                        width: 50%;
                        top: 50%;
                        right: 0;
                        display: block;
                        position: absolute;
                        margin-top: -1.4rem;
                        z-index: -1;
                    }

                    .p-menuitem-link {
                        .p-steps-number {
                            background-color: $open;

                            &:after {
                                color: $white !important;
                                background-color: $open;
                            }
                        }
                    }
                }
            }

            &.active {
                &::before {
                    border-top: 2px solid $open;
                }

                .p-steps-title {
                    color: $open !important;

                }

                .p-steps-number {

                    &:after {
                        color: $open !important;
                    }
                }

                &:has(+ :not(.active)) {
                    &::before {
                        width: 50%;
                    }

                    &::after {
                        content: " ";
                        border-top: 2px solid $lightest-gray;
                        width: 50%;
                        top: 50%;
                        right: 0;
                        display: block;
                        position: absolute;
                        margin-top: -1.4rem;
                        z-index: -1;
                    }

                    .p-menuitem-link {
                        .p-steps-number {
                            background-color: $open;

                            &:after {
                                color: $white !important;
                                background-color: $open;
                            }
                        }
                    }
                }

            }

            &.p-disabled {
                opacity: 1;

                &::before {
                    border-top: 2px solid $lightest-gray;
                }

                .p-menuitem-link {
                    .p-steps-number {
                        &:after {
                            color: $lightest-gray;
                        }
                    }

                }
            }

            &::before {
                content: " ";
                border-top: 2px solid $lightest-gray;
                width: 100%;
                top: 50%;
                left: 0;
                display: block;
                position: absolute;
                margin-top: -1.4rem;
            }

            &.p-steps-current {
                .p-menuitem-link {
                    .p-steps-title {
                        color: $open;
                        font-weight: normal;
                        white-space: pre-wrap;
                        text-align: center;
                    }

                    .p-steps-number {
                        &:after {
                            color: $draft;
                        }
                    }
                }
            }

            .p-menuitem-link {
                background: transparent;
                cursor: pointer;

                &:not(.p-disabled):focus {
                    box-shadow: none;
                }

                .p-steps-title {
                    color: $light-gray;
                    font-size: .75rem;
                    white-space: pre-wrap;
                    text-align: center;

                }

                .p-steps-number {
                    position: relative;
                    min-width: 1.25rem;
                    height: 1.25rem;
                    line-height: 1.25rem;
                    font-size: 1rem;
                    z-index: 0;

                    &:after {
                        position: absolute;
                        content: "\f8f2";
                        font-family: 'Material Symbols Outlined';
                        font-weight: 300;
                        font-style: normal;
                        font-size: 15px;
                        line-height: 1;
                        letter-spacing: normal;
                        text-transform: none;
                        display: inline-block;
                        white-space: nowrap;
                        word-wrap: normal;
                        direction: ltr;
                        -webkit-font-feature-settings: 'liga';
                        -webkit-font-smoothing: antialiased;
                        border: 0;
                        color: $lightest-gray;
                        background-color: $white;
                        border-radius: 2rem;
                    }
                }
            }
        }
    }
}