.accordion-minimal {
    .p-accordion-content {
        border: 0;
        padding: 0;
    }
    .p-accordion-header {
        .p-accordion-header-link {
            border-radius: 10px !important;
            border: none;
        }
    }
}
