.sidebar {
    position: relative;
    z-index: 1021;
    width: 100px;

    @include mq("tablet", "min") {
        min-height: 100vh;
    }

    @include mq("tablet") {
        width: 4rem;
    }

    .cancel-icon {
        display: none;
    }

    &.active {
        width: 170px;

        .cancel-icon {
            display: block;
            position: absolute;
            top: .4375rem;
            right: .4375rem;
            z-index: 6;
        }

        .logo-full {
            display: block;
        }

        .logo-bagde {
            display: none;
        }

        .p-megamenu .p-menuitem-link .p-menuitem-icon+.p-menuitem-text {
            display: inline-block;
            color: $menu-link;
            margin-left: .625rem;
        }

        .p-megamenu .p-menuitem-link.p-menuitem-link.active .p-menuitem-icon+.p-menuitem-text {
            color: $white;
        }

        .p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link {
            justify-content: start;
            padding-left: 1.25rem;
        }
    }

    .logo-wrapper {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .logo {
        max-width: 2.75rem;

        @include mq("tablet") {
            max-width: 2rem;
        }
    }

    .logo-full {
        display: none;
    }


    .nav {
        .nav-link {
            transition: 0.2s ease-out;
            padding: 1rem;
            color: $menu-link;

            &.active {
                color: $white;
            }

            .pi {
                font-size: 1.25rem;
            }

            // following rules moved to the theme
            //     &:hover {
            //         background-color: lighten($color: $primary, $amount: 5);
            //     }
            //     &.active {
            //         background-color: darken($color: $primary, $amount: 5);
            //     }
        }
    }

    .menu-icon {
        font-family: 'Material Symbols Outlined';
        font-size: 1.875rem;
        font-weight: 200;

        &.menu-icon-load {
            &::before {
                content: "\f86b";
            }
        }

        &.menu-icon-customer {
            &::before {
                content: "\e8d3";
            }
        }

        &.menu-icon-carrier {
            &::before {
                content: "\f8f2";
            }
        }

        &.menu-icon-accounting {
            &::before {
                content: "\e850";
            }
        }

        &.menu-icon-administration {
            &::before {
                content: "\f835";
            }
        }

        &.menu-icon-create-load {
            &::before {
                content: "\e147";
            }
        }

        &.menu-icon-edi {
            &::before {
                content: "\f7f9";
            }
        }

        &.menu-icon-tracking-board {
            &::before {
                content: "\e473";
            }
        }

        &.menu-icon-facility {
            &::before {
                content: "\ea40";
            }
        }

        &.menu-icon-award {
            &::before {
                content: "\f528";
            }
        }
    }
}

.p-sidebar .p-sidebar-header {
    padding: 1rem;
    border-bottom: 1px solid #D9D9D9;
}

.p-sidebar .p-sidebar-header+.p-sidebar-content {
    padding-top: 1rem;
}