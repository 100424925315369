.p-datatable-theme {
    &.p-datatable {
        .p-datatable-header {
            background: $white;
            border: 0;
            padding: 1rem 0;
        }
        .p-datatable-thead {
            > tr {
                > th {
                    background: $light-gray;
                    border-bottom: none;
                    &:first-child {
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;
                    }
                    &:last-child {
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                    }
                }
            }
        }
        .p-datatable-tbody {
            > tr {
                > td {
                    padding: 1.25rem 1rem;
                }
            }
        }
    }
}
