.media {
    color: $black;

    .d-flex {
        margin-bottom: 1.25rem;
    }

    p {
        margin-bottom: 5px;
    }

    span {
        color: #6B6B6B;
        margin-left: 5px;
        font-size: 10px;
        font-weight: normal;
    }

    img {
        width: 30px;
        height: 30px;
    }
}