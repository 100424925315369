.p-dialog .p-dialog-header {
    color: $body-color;
    padding: 10px 20px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom: 1px solid #D9D9D9;
}

.p-dialog .p-dialog-header .p-dialog-title {
    color: $body-color;
    font-weight: 400;
    font-size: 1rem;
}

.p-dialog .p-dialog-content:last-of-type {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.p-dialog .p-dialog-content {
    color: $body-color;
    padding: 20px;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
    box-shadow: none;
}

.p-dialog .p-dialog-footer {
    padding: 0 1.25rem 1.25rem 1.25rem;
}

.p-dialog {
    &.p-confirm-dialog {
        .p-dialog-footer {
            display: flex;
            flex-direction: row-reverse;
        }
    }
}

.p-dialog .modal-custom-footer {
    margin: 0 -1.25rem -1.25rem -1.25rem;
    padding: 10px 20px;
}

#AP-carrierbills .p-dialog .p-dialog-content {
    padding-top: 10px;
}