header {
    &.header {
        .header-border {
            border-bottom: 1px solid $light-gray;
        }

        .nav-pills {
            .nav-link {
                font-size: 1.25rem;
                padding-left: .375rem;
                padding-right: .375rem;

                @include mq(tablet) {
                    // padding: 0.25rem 1.125rem;
                }
            }
        }

        .user-avatar {
            height: 2rem;
            width: 2rem;
        }

        .user-text {
            font-size: .75rem;
        }

        .user-dropdown {
            .dropdown-item {
                font-size: .75rem;
                color: $black;
            }
        }
    }
}

body {
    &.header-without-line {
        header {
            &.header {
                .header-border {
                    border-bottom: none;
                }
            }
        }
    }
}