.p-tabview {
    &.menu-primary {
        .p-tabview-nav li.p-highlight .p-tabview-nav-link {
            background: #ffffff;
            border-color: $primary;
            color: $body-color;
        }

        .p-tabview-nav li .p-tabview-nav-link {
            color: $body-color;
        }
    }

    .p-tabview-nav li.p-highlight .p-tabview-nav-link {
        background: #ffffff;
        border-color: $black;
        color: $black;
    }

    .p-tabview-panels {
        padding: 10px;
    }
}

.menu-vertical,
.tab-vertical {

    &.p-tabview {
        display: flex;

        .p-tabview-nav-container {
            min-width: 13.125rem;
        }

        .p-tabview-panels {
            flex-grow: 1;
            padding-top: 0;
            padding-right: 0;
            padding-left: .625rem; //10px

            .p-tabview-title {
                font-weight: 600;
                font-size: 11px;
            }

            .table> :not(caption)>*>* {
                background-color: transparent;
            }
        }
    }

    &.p-tabmenu,
    &.p-tabview {
        min-width: 13.75rem;
        border-width: 1px;
        border-color: $lightest-gray;

        .p-tabmenu-nav,
        .p-tabview-nav {
            flex-direction: column;
            border-width: 0;

            .p-tabmenuitem,
            li {
                display: block;

                .p-menuitem-link,
                .p-tabview-nav-link {
                    padding: .8125rem .625rem;
                    border-radius: 0;
                    margin: 0;
                    border-width: 1px;
                    border-color: $lightest-gray;
                    font-weight: normal;
                    font-size: 12px;
                    color: $black;
                    border-color: $lightest-gray;
                    box-shadow: none;

                    &:not(.p-disabled) {
                        &::focus {
                            box-shadow: none;
                        }
                    }
                }

                &:not(.p-highlight) {
                    &:not(.p-disabled) {
                        &:hover {

                            .p-menuitem-link,
                            .p-tabview-nav-link {
                                border-color: $lightest-gray;
                                box-shadow: none;
                            }
                        }
                    }
                }

                &.p-highlight {

                    .p-menuitem-link,
                    .p-tabview-nav-link {
                        border-width: 1px;
                        background: $primary;
                        border-color: $lightest-gray;
                        color: $white;
                        border-radius: 0;
                        border-color: $lightest-gray;
                        box-shadow: none;
                    }
                }
            }
        }
    }

    &.tab-inner {
        &.p-tabview {
            display: block;
        }
    }

    .tab-inner {

        &.p-tabview .p-tabview-nav {
            display: block;
            border-bottom: 1px solid $light-gray;
            font-size: 0;
            padding-left: 10px;
        }

        &.p-tabview .p-tabview-nav li {
            display: inline-block;
        }

        &.p-tabview .p-tabview-panels,
        &.p-tabview .p-tabview-nav,
        &.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
            background-color: $grid;
        }

        &.p-tabview .p-tabview-nav li .p-tabview-nav-link {
            background-color: $grid;
            padding-left: 10px;
            padding-right: 10px;
        }

        &.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
            color: $primary;
            border-bottom: 3px solid $primary;
        }

        &.p-tabview .p-tabview-nav li .p-tabview-nav-link,
        &.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
            border-left-width: 0;
            border-right-width: 0;
            border-top-width: 0;
            color: $body-color;
        }
    }
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none;
}

.nav-arrows {

    &.nav-pills {
        gap: 8px;

        .nav-item {
            .nav-link {
                border: 1px solid $light-gray;
                padding: .625rem 1.5rem;
                position: relative;
                z-index: 1;

                &.nav-link-title-only {
                    h3 {
                        position: relative;
                        top: 9px;
                    }
                }

                span {
                    color: $light-gray;
                }

                &.active {
                    z-index: 0;

                    span {
                        color: $white;
                    }
                }
            }

            &:first-child {
                .nav-link {

                    // first arrow-head when not-active
                    &::after {
                        content: "";
                        position: absolute;
                        top: 12px;
                        right: -16px;
                        bottom: 0;
                        width: 33px;
                        height: 33px;
                        border: 1px solid $light-gray;
                        background-color: $body-bg;
                        z-index: 1;
                        transform: rotate(45deg) skew(10deg, 10deg);
                        border-radius: 3px;
                        transform-origin: center center;
                        border-left: 0;
                        border-bottom: 0;
                        border-top-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }

                    // first arrow-head when active
                    &.active {
                        &:after {
                            display: none;
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            top: 12px;
                            right: -16px;
                            bottom: 0;
                            width: 33px;
                            height: 33px;
                            border: 1px solid $primary;
                            background-color: $primary;
                            z-index: -1;
                            transform: rotate(45deg) skew(10deg, 10deg);
                            border-radius: 3px;
                            transform-origin: center center;
                            border-left: 0;
                            border-bottom: 0;
                        }
                    }
                }
            }

            &:not(:last-child) {
                .nav-link {

                    // middle arrow-heads when not active
                    &::after {
                        content: "";
                        position: absolute;
                        top: 12px;
                        right: -16px;
                        bottom: 0;
                        width: 33px;
                        height: 33px;
                        border: 1px solid $light-gray;
                        background-color: $body-bg;
                        z-index: 1;
                        transform: rotate(45deg) skew(10deg, 10deg);
                        border-radius: 3px;
                        transform-origin: center center;
                        border-left: 0;
                        border-bottom: 0;
                        border-top-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }

                    &.active {

                        // middle arrow-heads when active
                        &::after {
                            content: "";
                            position: absolute;
                            top: 12px;
                            right: -16px;
                            bottom: 0;
                            width: 33px;
                            height: 33px;
                            border: 1px solid $primary;
                            background-color: $primary;
                            z-index: 1;
                            transform: rotate(45deg) skew(10deg, 10deg);
                            border-radius: 3px;
                            transform-origin: center center;
                            border-left: 0;
                            border-bottom: 0;
                            border-top-left-radius: 0;
                            border-bottom-right-radius: 0;
                        }
                    }
                }
            }

            &:not(:first-child) {
                .nav-link {
                    padding: .625rem 1.5rem .625rem 2rem;
                    border-left: 0;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;

                    // all arrow-tails except first
                    &::before {
                        content: "";
                        position: absolute;
                        top: 11px;
                        left: -17px;
                        bottom: 0;
                        width: 35px;
                        height: 35px;
                        border: 1px solid $light-gray;
                        z-index: 1;
                        transform: rotate(45deg) skew(10deg, 10deg);
                        border-radius: 3px;
                        transform-origin: center center;
                        border-left: 0;
                        border-bottom: 0;
                        border-top-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }

                    &.active {

                        // all arrow-tails except first
                        &::before {
                            content: "";
                            position: absolute;
                            top: 11px;
                            left: -17px;
                            bottom: 0;
                            width: 35px;
                            height: 35px;
                            border: 1px solid $light-gray;
                            background-color: $body-bg;
                            z-index: 1;
                            transform: rotate(45deg) skew(10deg, 10deg);
                            border-radius: 3px;
                            transform-origin: center center;
                            border-left: 0;
                            border-bottom: 0;
                            border-top-left-radius: 0;
                            border-bottom-right-radius: 0;
                        }
                    }
                }
            }

            .nav-link,
            .show {

                &.active,
                >.navlink {

                    &::after {
                        border-left-color: $primary;
                    }

                    border: 1px solid $primary;

                    h3 {
                        color: $white;
                    }
                }
            }
        }

    }

}

form {
    &:not(.tabs-bill-form) {
        .tabs-bill {
            .p-tabview-nav-next {
                right: 104px;
            }

            .p-tabview-nav-container {
                padding-right: 115px;
            }
        }
    }

    .tabs-bill {
        &.p-tabview .p-tabview-panels {
            padding: 1rem 0;
        }
    }
}


.tabs-primary {
    &.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
        background: #ffffff;
        border-color: $primary;
        color: $primary;
    }
}

.tab-carrier-compliance {

    &.p-tabview .p-tabview-panels,
    &.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link,
    &.p-tabview .p-tabview-nav,
    &.p-tabview .p-tabview-nav li .p-tabview-nav-link,
    &.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link,
    .table> :not(caption)>*>* {
        background-color: $grid;
    }
}

.only2record .p-tabview-nav-next{
    display: none;
}