//p-dropdown
.p-dropdown,
.p-multiselect {
    border-radius: 0;

    .p-dropdown-label {
        color: #6c757d;
    }
}

.p-inputwrapper-filled .p-dropdown-label {
    color: $body-color;
}

.p-dropdown,
.p-multiselect,
.p-autocomplete-multiple {
    .p-icon {
        width: .625rem;
        height: .625rem;
    }
}

.p-multiselect .p-multiselect-label {
    padding: 0.41rem 0.5625rem;
}

.p-multiselect .p-multiselect-label {
    font-size: .625rem;
}

.p-dropdown:not(.p-disabled):hover {
    border-color: $secondary;
}

.p-dropdown,
.p-multiselect {
    border-color: $lightest-gray;
}

.p-dropdown:not(.p-disabled).p-focus,
.p-multiselect:not(.p-disabled).p-focus {
    box-shadow: none;
    border-color: $secondary;
}

.p-dropdown-item,
.p-multiselect-item {
    font-size: .625rem;
}

p-inputmask.ng-dirty.ng-invalid>.p-inputtext,
p-multiselect.ng-dirty.ng-invalid>.p-multiselect {
    border-color: $danger !important;
    background-color: $alert-errorlight;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: 0.17rem 0.5rem;
    background: $lightest-gray;
    color: $body-color;
    border-radius: 0;
    margin-right: .375rem;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: $lightest-gray;
    color: $body-color;
}

.p-dropdown-item {
    font-size: 10px;
    padding: 0.41rem 0.5625rem;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 0.41rem 0.5625rem;
}

.p-dropdown-clearable {
    timesicon.p-icon-wrapper {
        width: 16px;
        height: 25px;
    }

    .p-dropdown-clear-icon {
        margin-top: -5px;
        right: 40px;
    }
}

.dropdown-link {
    border: none;
    background: transparent;
    top: -2px;

    .p-inputtext {
        font-size: 12px;
        color: $dark-gray;
        padding: 0;
        max-width: 120px;
        text-overflow: ellipsis;
    }

    .p-dropdown-trigger {
        width: auto;
    }

    .p-dropdown-item {
        font-size: 12px;
        color: #6b6b6b;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
        padding: .625rem 1rem;
        border-bottom: 1px solid #D9D9D9;
    }

    .p-dropdown-panel .p-dropdown-items {
        padding: 0;
    }

    .ellipsis {
        width: 135px;
    }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item,
.p-multiselect-panel .p-multiselect-items .p-multiselect-item,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: $body-color;
}

.p-dropdown {
    .p-dropdown-trigger {
        width: 2rem;
    }
}

.p-multiselect {
    &.drop-users {
        width: 145px;
    }
}